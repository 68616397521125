import './Footer.css'

export const Footer = (
  ) => {

    return (
        <div className='footer'>
        React | pitt.b.ultimate@gmail.com  | Inspired by  <a href = "https://gttribe.github.io/site/" className='gt'>GT Tribe</a>


        </div>
    )
}